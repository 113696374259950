import { blinkConstants } from '@thoughtspot/blink-constants';
import { useEmbedContext, useGetErrorInfo } from '@thoughtspot/blink-context';
import * as session from '@thoughtspot/session-service';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '/@contexts/global-app-context';
import { useMduBannerContext } from '/@contexts/mdu-banner-context';
import { useIsSessionActiveQuery } from '/@services/session/session-service';
import { TRUSTED_AUTH_COOKIELESS } from '/@utils/embed.util';
import { isEmbeddedApp as isEmbedded } from '/@utils/embed-base.util';
import {
    getStatusCodeFromPrismError,
    isCurrentlyLoggedIn,
    notifyOnAuthExpiration,
} from './navigate-to-login-check.utils';

const LOGIN = 'login';
const PRINT = 'print';
const UNIVERSAL = 'universal';
export const InitSessionCheckContainer: React.FC = () => {
    const { mduBannerConfig, setMduBannerConfig } = useMduBannerContext();
    const { navService, sessionService, systemConfigService } = useAppContext();
    const location = useLocation();
    const embedConfig = useEmbedContext();
    const { resetErrors } = useGetErrorInfo();

    const {
        startPolling,
        stopPolling,
        error,
        data,
    } = useIsSessionActiveQuery();

    useEffect(() => {
        startPolling(blinkConstants.ACTIVE_SESSION_CHECK_TIME_PERIOD);
    }, []);

    useEffect(() => {
        let status = null;
        if (error) {
            status = getStatusCodeFromPrismError(error);
        } else if (data) {
            status = data.isSessionActive.status;
            const notificationBanner =
                data.isSessionActive.meta?.notificationBanner;
            if (notificationBanner) {
                setMduBannerConfig?.(notificationBanner);
            } else if (mduBannerConfig) {
                setMduBannerConfig?.(null);
            }
        }

        if (status === blinkConstants.HTTP_STATUS_NOT_LOGGED_IN) {
            if (
                isEmbedded() &&
                (isCurrentlyLoggedIn ||
                    embedConfig.embedParams.authType ===
                        TRUSTED_AUTH_COOKIELESS)
            ) {
                // If the user gets logged out of the session from within the embedded app for
                // some reason maintain the same state and notify the parent about the failure.
                // and shoud not display the 'something went wrong' error due to invalid session.
                // will be updated the session in background.
                resetErrors();
                // eslint-disable-next-line promise/catch-or-return
                notifyOnAuthExpiration().then(() => {
                    return null;
                });
            } else if (
                location?.pathname.indexOf(LOGIN) === -1 &&
                location?.pathname.indexOf(PRINT) === -1 &&
                location?.pathname.indexOf(UNIVERSAL) === -1
            ) {
                if (isCurrentlyLoggedIn) {
                    session.markAutomaticallyLoggedOut();
                }
                if (!navService.shouldShowDeepLinkPage()) {
                    stopPolling();
                    // setTimeout to wait for component rendering
                    // can not navigate to another component while component is rendering
                    setTimeout(() => {
                        // TEMP: Changes is only for OKTA case, This should be generic fix as part of SCAL-92730: Oidc logout ui changes
                        const issuerLink = systemConfigService.getOidcLoginUriFromIssuers();
                        // This condition is only for okta enabled environment
                        if (
                            systemConfigService.isOidcEnabled() &&
                            systemConfigService.isOktaEnabled() &&
                            issuerLink
                        ) {
                            sessionService.markManuallyLoggedOut();
                            const { origin } = new URL(issuerLink);
                            window.location.replace(
                                `${origin}/signin?clearSession=true`,
                            );
                        }

                        sessionService.setSessionInfo(null);
                        sessionService.markManuallyLoggedOut();
                        sessionService.clearSessionInfo();
                        sessionService.getOnUserLoggedOut().publish();
                        navService.goToLogin();
                        // reload is workaround to show the login page. Currently it is navigating to home page and
                        // after reload it is navigating to login page. This fix is required.
                        navService.reload();
                    });
                }
            }
        }
    }, [data, error]);
    return null;
};
