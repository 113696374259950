import { getContextState } from '@thoughtspot/blink-context';
import { decodeBase64URLString } from '@thoughtspot/codec-util';
import { getClientLocale } from '@thoughtspot/i18n';
import { create } from '@thoughtspot/logger';
import type { MetricsService } from '@thoughtspot/metrics';
import {
    collectEvent,
    initEventCollector,
    initMixpanel,
    setEventCollector,
} from '@thoughtspot/metrics';
import {
    checkIfMixpanelAPIHostEnabledAndReturn,
    getMixpanelAccessToken,
    getMixpanelConfig,
    isPublicUserEnabled,
} from '@thoughtspot/system-config-service';
import mixpanel from 'mixpanel-browser';
import { isAppInIFrame } from '/@utils/embed-base.util';
import { getSpotterPlanType } from '../conv-assist/conv-assist-service.util';
import { addEverywhereEventProps } from '../everywhere/everywhere-service.util';
import { FLAGS, flags } from '../system/config-service/flags-service';
import {
    flagMixpanelDNT,
    getAccessibleOrgs,
    getClusterId,
    getClusterName,
    getCurrentOrgId,
    getFirstLoginDate,
    getIsTeamEditionOwner,
    getJSessionId,
    getReleaseVersion,
    getSubscriptionType,
    getTeamId,
    getUserEmail,
    getUserGuid,
    getUserPersona,
    getUserPrivileges,
    hasAdminPrivileges,
    hasAnalystEquivalentPriveleges,
    hasDataManagementPrivileges,
    hasPreviewThougtspotSagePrivileges,
    hasSeenOnboarding,
    isAnswerV2Enabled,
    isConvAssistEnabled,
    isDataManagementCheckEnabled,
    isDataPanelV2Enabled,
    isDynamicTitleSupported,
    isEmbraceEnabled,
    isFirstLogin,
    isLiveboardEnabled,
    isOrgUiEnabled,
    isPinboardV2Enabled,
    isRAnalysisEnabled,
    isRolesEnabled,
    isSaaSCluster,
    isTeamsEnabled,
    isTrialVersionEnabled,
    trialDaysLeftWrapper,
} from '../system/config-service/session-service';
import { initAutoTracking } from './auto-track-service';

export { MetricsService };

const Logger = create('v2-metrics-service');

export async function initMetricsService() {
    // to be implemented
    (window as any).eventList = [];
    return initMixpanel({
        isMixpanelEnabled: !!getMixpanelConfig().enabled,
        enableCrossSubDomainCookie: !!getMixpanelConfig()
            .enableCrossSubDomainCookie,
        mixpanelLib: mixpanel,
        addEverywhereEventProps,
        mixpanelToken: getMixpanelAccessToken(),
        isEmbedded: isAppInIFrame(),
        // eslint-disable-next-line no-restricted-globals
        getPath: () => location.hash.replace('#', ''),
        getContextState,
        isOrgEnabled: isOrgUiEnabled(),
        mockEventQueue: (window as any).eventList,
        isE2E: flags.getValue(FLAGS.e2eTest.name),
        isDebugModeOn: flags.getValue(FLAGS.debug.name),
        mixpanelApiHost: checkIfMixpanelAPIHostEnabledAndReturn(),
        isLiveboardEnabled: isLiveboardEnabled(),
        isTrialVersionEnabled: isTrialVersionEnabled(),
        useLocalStorage: !!getMixpanelConfig().useLocalStorageForPersistence,
    });
}

export function initEventCollection() {
    initEventCollector({
        // All the properties listed here may not show in the Mixpanel dashboard.
        // You need to set these properties at 'loggedInProperties' inside 'js/ts-packages/metrics/src/event-collector.ts'. 
        userGUID: getUserGuid(),
        userEmail: getUserEmail(),
        firstLoginDate: getFirstLoginDate(),
        isFirstLogin: isFirstLogin(),
        firstUtmSource: flags.getValue(FLAGS.utm_source.name),
        firstUtmMedium: flags.getValue(FLAGS.utm_medium.name),
        preferredUserLocale: getClientLocale(),
        partialJSessionGUID: getJSessionId(),
        hasAdminPrivileges: hasAdminPrivileges(),
        selfClusterId: getClusterId(),
        selfClusterName: getClusterName(),
        flagMixpanelDNT,
        userPrivileges: getUserPrivileges(),
        isEmbraceEnabled: isEmbraceEnabled(),
        isSaaSCluster: isSaaSCluster(),
        releaseVersion: getReleaseVersion(),
        userPersona: getUserPersona(),
        hasDataManagementPrivileges: isRolesEnabled() 
        ? hasAnalystEquivalentPriveleges(): hasDataManagementPrivileges(),
        onboardingDataManagemenentFlag: isDataManagementCheckEnabled(),
        hasSeenOnboarding: hasSeenOnboarding(),
        isAnswerV2Enabled: isAnswerV2Enabled(),
        isPinboardV2Enabled: isPinboardV2Enabled(),
        isAnonymousUser: isPublicUserEnabled(),
        trialExpiryDate: trialDaysLeftWrapper(),
        planType: getSubscriptionType(),
        teamId: getTeamId(),
        mixpanelApiHost: checkIfMixpanelAPIHostEnabledAndReturn(),
        isOrgEnabled: isOrgUiEnabled(),
        orgId: getCurrentOrgId().toString(),
        userOrgs: getAccessibleOrgs(),
        isRAnalysisEnabled: isRAnalysisEnabled(),
        isDataPanelV2Experience: isDataPanelV2Enabled(),
        teamOwner: getIsTeamEditionOwner(),
        isTeamEnabled: isTeamsEnabled(),
        isTrialVersionEnabled: isTrialVersionEnabled(),
        isConvAssistEnabled: isConvAssistEnabled(),
        blinkReleaseVersion: '',
        hasAIPrivilege: hasPreviewThougtspotSagePrivileges(),
        spotterPlanType: getSpotterPlanType(),
        isDynamicParameterEnabled: isDynamicTitleSupported(),
    });
    initAutoTracking(collectEvent);
}

export const collectQueryParamEvent = (url: string) => {
    const search = url?.split('?')?.[1];
    if(search?.includes('loadEvent')) {
        const loadEventBase64String = search?.split('&').find(searchParam => searchParam.includes('loadEvent')).replace('loadEvent=','');
        try {
            const loadEventObj = JSON.parse(decodeBase64URLString(loadEventBase64String));
            collectEvent(loadEventObj[0], loadEventObj[1]);
        } catch (err) {
            Logger.warn('Load event collection failed', err);
        }
    }
}

export const isMixpanelAllowed = () => {
    return !!getMixpanelConfig().enabled && !flags.getValue(FLAGS.e2eTest.name);
}

export const disableMetricsService = () => {
    setEventCollector(false);
}

export {
    collectEvent,
    updateMixPanelProperty,
    PeoplePropertiesEnum,
    PeopleIncrementalPropertiesEnum,
    PeopleSetPropertiesEnum,
    PeoplePropertiesTypeEnum,
    SessionPropsEnum,
    EventUpdatedPropertiesEnum,
} from '@thoughtspot/metrics';
