import { AppLogoType } from '@thoughtspot/custom-style-service';
import { HelpMenuContainer, OrgMenuContainer } from '@thoughtspot/global-nav';
import { useTranslation } from '@thoughtspot/i18n';
import { UserAction, userActionTypeEnum } from '@thoughtspot/metrics';
import { getOnEnter } from '@thoughtspot/radiant-react/widgets/_utils/index';
import { IconID } from '@thoughtspot/radiant-react/widgets/icon';
import { Horizontal } from '@thoughtspot/radiant-react/widgets/layout';
import { RoutedLink } from '@thoughtspot/radiant-react/widgets/link';
import { DataBaseMode, UserPersona } from '@thoughtspot/session-service';
import {
    getClientId,
    getIdpDomain,
    isEmbeddedUseCase,
} from '@thoughtspot/system-config-service';
import React, { useLayoutEffect, useRef } from 'react';
import { useMatches } from 'react-router';
import { useAppContext } from '/@contexts/global-app-context';
import { isEmbeddedApp } from '/@utils/embed-base.util';
import { Applications, ROUTE_IDS } from '../../../routing/routes';
import { useAnalystStudioMutation } from '../custom-action-hooks/useAnalystStudioCustomHook';
import { NavLinkConfig } from '../global-nav';
import styles from './classic-nav.module.scss';
import { ClassicNavItems } from './classic-nav-items.container';

export interface ClassicNavProps {
    applicationSwitcherConfig?: NavLinkConfig[];
    searchBar?: React.ReactChild;
    userProfile?: React.ReactChild;
    shouldShowPendoHelp: boolean;
    useAddUserToModeMutation: any;
    useGetAnalystStudioAccessInfoLazyQuery: any;
    isEmbedded?: boolean;
}

export enum ClassicNavTestIDs {
    HomeLink = 'home-link',
}

export interface ClassicNavItem {
    appName: string;
    homePath: string;
    shouldShow: () => boolean;
    shouldRedirectToV1?: boolean;
    isActive: boolean;
    clickHandler: () => void;
    titleIconId?: IconID;
}

const AppLogoDefault = '/resources/img/logo/TS-logo-white-no-bg.svg';

export const ClassicNavExperience: React.FC<ClassicNavProps> = ({
    applicationSwitcherConfig,
    searchBar,
    userProfile,
    shouldShowPendoHelp,
    useAddUserToModeMutation,
    useGetAnalystStudioAccessInfoLazyQuery,
    isEmbedded = isEmbeddedApp(),
}) => {
    const { t } = useTranslation();
    const matches = useMatches();
    const {
        sessionService,
        metricsService,
        customStylingService,
        navService,
    } = useAppContext();

    const {
        getAccessToken,
        getCurrentOrgId,
        getOktaUserId,
        getUserPrivileges,
    } = sessionService;

    // Initialize mutation functions for Analyst Studio
    const { onAnalystStudioClicked } = useAnalystStudioMutation(
        getAccessToken, // Access token for authentication
        getOktaUserId, // User ID from Okta
        getUserPrivileges, // User privileges for access control
        getCurrentOrgId, // Current organization ID
        useAddUserToModeMutation, // Mutation hook for adding user to a mode
        useGetAnalystStudioAccessInfoLazyQuery, // Lazy query hook for fetching Analyst Studio access info
        getIdpDomain, // IDP domain for configuration
        getClientId, // Client ID for configuration
    );

    const areProfileAndHelpHidden = sessionService.areProfileAndHelpHidden();
    const isOrgEnabled =
        sessionService.isOrgUiEnabled() && !sessionService.hideOrgSwitcherUi();

    const logo = customStylingService
        .getConfig()
        ?.getAppLogoUrl(AppLogoType.DEFAULT, AppLogoDefault);
    const logoRef = useRef(null);
    useLayoutEffect(() => {
        if (logoRef.current) {
            logoRef.current.style.setProperty(
                'background-image',
                `url('${logo}')`,
                'important',
            );
        }
    }, [logoRef.current]);

    const navBackground = customStylingService.getConfig()?.getAppPanelColor()
        .color;
    const navBackgroundRef = useRef(null);

    useLayoutEffect(() => {
        if (navBackgroundRef.current) {
            navBackgroundRef.current.style.setProperty(
                'background-color',
                `var(--ts-var-nav-background, ${navBackground})`,
                'important',
            );
        }
    }, [navBackgroundRef.current]);

    const HomeButtonContent = React.useMemo(() => {
        const clickHandler = () => {
            navService.goToHome();
            metricsService.collectEvent(
                new UserAction(userActionTypeEnum.TOP_NAV_LOGO_CLICK),
                {
                    isDefaultLogo: true,
                },
            );
        };
        return (
            <RoutedLink
                href="#/home"
                className={styles.homeLink}
                role="button"
                tabIndex={0}
                onClick={clickHandler}
                data-testid={ClassicNavTestIDs.HomeLink}
                aria-label={t('Thoughtspot.home')}
                onKeyDown={getOnEnter(clickHandler)}
            >
                <div className={styles.tsLogo} ref={logoRef} />
            </RoutedLink>
        );
    }, [logoRef, navService, metricsService]);

    const appShouldShow = (applicationID: string): boolean => {
        return (
            applicationSwitcherConfig.find(
                applicationSwitcherConfig =>
                    applicationSwitcherConfig.id === applicationID,
            ) !== undefined
        );
    };

    const linkIsActive = (matchList: any[], routeList: string[]): boolean => {
        return matchList.some(match =>
            routeList.some(
                route => route.toLowerCase() === match.id.toLowerCase(),
            ),
        );
    };

    const ApplicationList: ClassicNavItem[] = React.useMemo(() => {
        return [
            {
                appName: t('primaryNavigation.HOME'),
                homePath: '/home',
                shouldShow: () => true,
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.EurekaPage,
                    ROUTE_IDS.Default,
                    ROUTE_IDS.Home,
                    ROUTE_IDS.StratusHome,
                    ROUTE_IDS.HomeAnswers,
                    ROUTE_IDS.HomeLiveboards,
                    ROUTE_IDS.HomeFavourites,
                    ROUTE_IDS.CreatedByMe,
                    ROUTE_IDS.RequestAccess,
                    ROUTE_IDS.SAMLComplete,
                    ROUTE_IDS.CaptainPage,
                ]),
                clickHandler: () => {
                    navService.goToHome();
                },
            },
            {
                appName: t('primaryNavigation.SEARCH'),
                homePath: '/insights/answer',
                shouldShow: () => !sessionService.isEurekaEnabled(),
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.Answer,
                    ROUTE_IDS.AiAnswer,
                    ROUTE_IDS.EditACopy,
                    ROUTE_IDS.AutoAnswer,
                    ROUTE_IDS.SearchAssist,
                ]),
                clickHandler: () => {
                    navService.goToAnswer();
                },
            },
            {
                appName: t('primaryNavigation.ANSWERS'),
                homePath: '/insights/answers',
                shouldShow: () => true,
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.Answers,
                    ROUTE_IDS.SavedAnswer,
                    ROUTE_IDS.SearchBarEmbed,
                    ROUTE_IDS.View,
                ]),
                clickHandler: () => {
                    navService.goToAnswers();
                },
            },
            {
                appName: t('primaryNavigation.PINBOARDS'),
                homePath: '/insights/home/liveboards',
                shouldShow: () => true,
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.Pinboard,
                    ROUTE_IDS.Pinboards,
                    ROUTE_IDS.Liveboards,
                    ROUTE_IDS.PinboardEmbed,
                    ROUTE_IDS.PinboardSchedule,
                    ROUTE_IDS.PrintView,
                    ROUTE_IDS.Tab,
                    ROUTE_IDS.TabEmbed,
                    ROUTE_IDS.VizEmbed,
                ]),
                clickHandler: () => {
                    navService.goToHomeLiveboards();
                },
            },
            {
                appName: t('primaryNavigation.SPOT_IQ'),
                homePath: '/insights',
                shouldShow: () => sessionService.isA3Enabled(),
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.SpotIQAnalySis,
                    ROUTE_IDS.SpotIQAnalysis,
                    ROUTE_IDS.SpotIQAnalysisV1Path,
                    ROUTE_IDS.SpotIQList,
                    'spotiq-analysis',
                    ROUTE_IDS.SpotIQ,
                ]),
                clickHandler: () => {
                    navService.goToInsights('');
                },
            },
            {
                appName: t('primaryNavigation.MONITOR'),
                homePath: '/insights/home/monitor-alerts',
                shouldShow: () => sessionService.isMonitorV2Enabled(),
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.MonitorAlerts,
                    ROUTE_IDS.Monitor,
                    'monitor-alerts',
                ]),
                clickHandler: () => {
                    navService.goToHomeMonitorAlerts();
                },
            },
            {
                appName: t('primaryNavigation.MANAGE_DATA'),
                homePath: '/data',
                shouldShow: () => appShouldShow(Applications.DATA),
                shouldRedirectToV1: !sessionService.isRedirectToV1Disabled(),
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.DataModelPage,
                    ROUTE_IDS.WorksheetAssociation,
                ]),
                clickHandler: () => {
                    navService.goToDataModelPage();
                },
            },
            {
                appName: t('primaryNavigation.ADMIN'),
                homePath: '/admin',
                shouldShow: () => appShouldShow(Applications.ADMIN),
                shouldRedirectToV1: !sessionService.isRedirectToV1Disabled(),
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.AdminPage,
                    ROUTE_IDS.WorksheetV2,
                ]),
                clickHandler: () => {
                    navService.goToAdminPage();
                },
            },
            {
                appName: t('primaryNavigation.DevHub'),
                homePath: '/develop',
                shouldShow: () => appShouldShow(Applications.DEVELOP),
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.EverywhereStandalonePage,
                    ROUTE_IDS.EverywherePage,
                ]),
                clickHandler: () => {
                    navService.goToDevelopTab();
                },
            },
            {
                appName: t('primaryNavigation.SETUP'),
                homePath: '/setup',
                shouldShow: () =>
                    sessionService.isSetupV2Enabled() &&
                    sessionService.getUserPersona() === UserPersona.ANALYST &&
                    sessionService.getDataBaseMode() !== DataBaseMode.FALCON &&
                    sessionService.isOnboardingEnabled() &&
                    sessionService.hasAnalystEquivalentPriveleges() &&
                    !sessionService.getIsAnalystOnboardingCompleted() &&
                    !isEmbeddedUseCase(),
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, ['setuphub']),
                clickHandler: () => {
                    navService.goToSetup();
                },
            },
            {
                appName: t('primaryNavigation.DEMO'),
                homePath: '/demo',
                shouldShow: () =>
                    sessionService.isTrialVersionEnabled() &&
                    sessionService.isFreeTrialAnalystOptionsEnabled(),
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, ['demo']),
                clickHandler: () => {
                    navService.goToOnBoarding();
                },
            },
            {
                appName: t('primaryNavigation.TEAMS'),
                homePath: '/teams',
                shouldShow: () => appShouldShow(Applications.TEAMS_ADMIN),
                shouldRedirectToV1: false,
                isActive: linkIsActive(matches, [
                    ROUTE_IDS.TeamsPage,
                    ROUTE_IDS.Purchase,
                ]),
                clickHandler: () => {
                    navService.goToTeamsMembers();
                },
            },
        ];
    }, [sessionService, matches, applicationSwitcherConfig]);

    return (
        <div className={styles.topNavV1} role="banner" ref={navBackgroundRef}>
            {HomeButtonContent}
            <ClassicNavItems applicationList={ApplicationList} />
            <Horizontal
                hAlignContent="right"
                className={styles.searchBarWrapper}
            >
                {searchBar}
            </Horizontal>
            {isOrgEnabled && (
                <OrgMenuContainer isLegacy isEmbedded={isEmbedded} />
            )}
            <Horizontal
                className={styles.helpAndUserProfileContainer}
                spacing="c"
                grow={0}
                shrink={0}
                hAlignContent="right"
            >
                {!areProfileAndHelpHidden && (
                    <HelpMenuContainer
                        isPendoHelpEnabled={shouldShowPendoHelp}
                    />
                )}
                {userProfile && !areProfileAndHelpHidden && userProfile}
            </Horizontal>
        </div>
    );
};
