import { LazyPromise } from '@thoughtspot/blink-context';
import {
    FEATURE_FLAG_NAMES,
    isFeatureEnabled,
} from '@thoughtspot/feature-service';
import { isOktaEnabled } from '@thoughtspot/system-config-service';
import { getIsUserAuthenticated } from '/@services/system/login-service';
import {
    canAccessAnalystStudio,
    canCreateOrEditConnections,
    canManageCustomCalendars,
    canManageWorksheetViewsTables,
    canShowTeamsTab,
    getIsSpotterFullExperienceEnabled,
    getSubscriptionType,
    getUserExpirationTime,
    getUserName,
    hasDataManagementPrivileges,
    hasUserDataUploadPrivileges,
    isA3Enabled,
    isAdminTabVisible,
    isAiAnswerGenerationEnabledForUser,
    isAnalystStudioEnabled,
    isEdgeEnabled,
    isEurekaEnabled,
    isEverywhereEnabled,
    isLoggedIn,
    isMonitorV2Enabled,
    isScheduleOnHomeEnabled,
    shouldHideDataTabOnNoManageDataPermission,
    SubscriptionType,
} from '../services/system/config-service/session-service';

export enum PrivilegeTypes {
    CopilotFreeTrialExpired = 'CopilotFreeTrialExpired',
    CopilotFreeTrialNotExpired = 'CopilotFreeTrialNotExpired',
    isSpotterFullExperienceEnabled = 'isSpotterFullExperienceEnabled',
    MustBeAuthenticated = 'MustBeAuthenticated',
    MustNotBeAuthenticated = 'MustNotBeAuthenticated',
    CanSeeEurekaSearch = 'CanSeeEurekaSearch',
    CanSeeAIAnswer = 'CanSeeAIAnswer',
    CanAccessMonitor = 'CanAccessMonitor',
    CanAccessLiveboardSchedulesOnHome = 'CanAccessLiveboardSchedulesOnHome',
    HasDeveloperAccess = 'HasDeveloperAccess',
    HasTeamsAccess = 'HasTeamsAccess',
    HasAdminAccess = 'HasAdminAccess',
    CanAccessA3 = 'CanAccessA3',
    CanAccessDataTab = 'CanAccessDataTab',
    HasAccessToCopilot = 'HasAccessToCopilot',
    CanAccessAnalystStudio = 'CanAccessAnalystStudio',
    CanSeeEdgeOnboarding = 'CanSeeEdgeOnboarding',
}

type Privileges = {
    [key in PrivilegeTypes]?: LazyPromise<void>;
};

const MustBeAuthenticated = new LazyPromise<void>((resolve, reject) => {
    return getIsUserAuthenticated()
        .then(isAuthenticated => (isAuthenticated ? resolve() : reject()))
        .catch(reject);
});

const MustNotBeAuthenticated = new LazyPromise<void>((resolve, reject) => {
    return getIsUserAuthenticated()
        .then(isAuthenticated => (isAuthenticated ? reject() : resolve()))
        .catch(() => resolve());
});

const getAuthenticatedSessionCheck = (
    booleanFlagCheck: () => boolean,
): LazyPromise<void> => {
    return new LazyPromise<void>((resolve, reject) => {
        // For V1 - When authentication is not handled by V2
        if (!!isLoggedIn && isLoggedIn()) {
            if (booleanFlagCheck()) {
                return resolve();
            }
            return reject();
        }
        return MustBeAuthenticated.then(() => {
            if (booleanFlagCheck()) {
                return resolve();
            }
            return reject();
        }).catch(reject);
    });
};

const IsCopilotFreeTrialUserExpired = () => {
    if (getSubscriptionType() !== SubscriptionType.FREE_TRIAL) {
        return false;
    }
    const expiryEpochTime = getUserExpirationTime();
    const currentEpochTime = Date.now();
    const isUserExpired = expiryEpochTime - currentEpochTime;
    return isUserExpired <= 0;
};

const getIsCopilotFreeTrialExpired = () => {
    return getAuthenticatedSessionCheck(() => IsCopilotFreeTrialUserExpired());
};

const getIsCopilotFreeTrialNotExpired = () => {
    return getAuthenticatedSessionCheck(() => !IsCopilotFreeTrialUserExpired());
};

const getIsEdgeEnabled = () => {
    return getAuthenticatedSessionCheck(() => isEdgeEnabled());
};

const isSpotterFullExperienceEnabled = () => {
    return getAuthenticatedSessionCheck(
        () =>
            getIsSpotterFullExperienceEnabled() || getUserName() === 'tsadmin',
    );
};

const hasDataTabAccess = () => {
    return getAuthenticatedSessionCheck(
        () =>
            hasDataManagementPrivileges() ||
            hasUserDataUploadPrivileges() ||
            canManageCustomCalendars() ||
            canCreateOrEditConnections() ||
            canManageWorksheetViewsTables() ||
            !shouldHideDataTabOnNoManageDataPermission(),
    );
};

const hasCopilotAccess = () => {
    return getAuthenticatedSessionCheck(
        () => isFeatureEnabled(FEATURE_FLAG_NAMES.COPILOT_ENABLED) as boolean,
    );
};

const hasAnalystStudioAccess = () => {
    return getAuthenticatedSessionCheck(
        () =>
            isAnalystStudioEnabled() &&
            isOktaEnabled() &&
            canAccessAnalystStudio(),
    );
};

// DO NOT CHANGE WITHOUT FE INFRA APPROVAL
export const BASE_PRIVILEGES: Privileges = {
    MustBeAuthenticated,
    MustNotBeAuthenticated,
};

/**
 * A privilege is a lazy promise that resolves to indicate authorization
 * Add new privileges under the relevant section
 */
export const PrivilegeList: Privileges = {
    ...BASE_PRIVILEGES,
    // INSIGHTS
    CanSeeEurekaSearch: getAuthenticatedSessionCheck(isEurekaEnabled),
    CanSeeAIAnswer: getAuthenticatedSessionCheck(
        isAiAnswerGenerationEnabledForUser,
    ),
    CanAccessMonitor: getAuthenticatedSessionCheck(isMonitorV2Enabled),
    CanAccessA3: getAuthenticatedSessionCheck(isA3Enabled),
    CanAccessLiveboardSchedulesOnHome: getAuthenticatedSessionCheck(
        isScheduleOnHomeEnabled,
    ),
    // DEVELOP
    HasDeveloperAccess: getAuthenticatedSessionCheck(isEverywhereEnabled),
    // TEAMS
    HasTeamsAccess: getAuthenticatedSessionCheck(canShowTeamsTab),
    // ADMIN
    HasAdminAccess: getAuthenticatedSessionCheck(isAdminTabVisible),
    // DATA
    CanAccessDataTab: hasDataTabAccess(),
    // COPILOT
    HasAccessToCopilot: hasCopilotAccess(),
    CopilotFreeTrialNotExpired: getIsCopilotFreeTrialNotExpired(),
    CopilotFreeTrialExpired: getIsCopilotFreeTrialExpired(),
    isSpotterFullExperienceEnabled: isSpotterFullExperienceEnabled(),
    // ANALYST STUDIO ACCESS
    CanAccessAnalystStudio: hasAnalystStudioAccess(),
    // EDGE
    CanSeeEdgeOnboarding: getIsEdgeEnabled(),
};

export const resetAllPrivileges = () => {
    Object.keys(PrivilegeList).forEach(privilege => {
        PrivilegeList[privilege].reset();
    });
};
