import React, { createContext, ReactNode, useContext, useState } from 'react';

interface MduBannerContextType {
    mduBannerConfig?: any;
    setMduBannerConfig?: (config: any) => void;
}

export const MduBannerContext = createContext<MduBannerContextType>({});

export const useMduBannerContext = () => useContext(MduBannerContext);

export const MduBannerContextProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [mduBannerConfig, setMduBannerConfig] = useState(null);

    return (
        <MduBannerContext.Provider
            value={{ mduBannerConfig, setMduBannerConfig }}
        >
            {children}
        </MduBannerContext.Provider>
    );
};
